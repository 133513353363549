import React from 'react'
import {FaMinusCircle} from 'react-icons/fa'
import {FaPlusCircle} from 'react-icons/fa'
import MultilineText from '@components/MultilineText'
import useState from '@hooks/useState'

import styles from './styles.module.css'

export default function Question(props) {
  const {question} = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={styles.question}>
      <h3 className={styles.questionText} onClick={() => setIsOpen(!isOpen)}>
        <span className={styles.icon}>
          {isOpen ? (
            <FaMinusCircle color="var(--primary-color)" />
          ) : (
            <FaPlusCircle color="var(--primary-color)" />
          )}
        </span>
        {question.question}
      </h3>
      <p className={styles.answer} aria-expanded={!isOpen}>
        <MultilineText>{question.answer}</MultilineText>
      </p>
    </div>
  )
}
