import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'

import Question from './Question'

import styles from './styles.module.css'

export default function Faq(props) {
  return (
    <Container size="small">
      <div className={styles.container}>
        <div className={styles.header}>
          {props.title ? <h1>{props.title}</h1> : null}
          {props.description ? <p>{props.description}</p> : null}
        </div>
        <div className={styles.questions}>
          {props.questions.map((question, index) => (
            <Question key={index} question={question} />
          ))}
        </div>
      </div>
    </Container>
  )
}
